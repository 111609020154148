a {
  &.center {
    justify-self: center;
    text-align: center;
  }
}

main {
  &.signIn {
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
    img {
      justify-self: center;
      width: auto;
      height: 200px;
      @media (max-width: 768px) {
        height: 200px;
      }
    }
    section {
      display: grid;
      justify-content: center;
      align-items: center;
      form {
        display: grid;
        h1 {
          justify-self: center;
          padding-bottom: 20px;
          font-size: 25px;
        }
        label {
          color: #949494;
          font-size: 14px;
        }
        input {
          margin: 20px auto;
          width: 300px;
          color: #3f3f3f;
          font-size: 14px;
          padding: 20px 30px;
          background-color: #edf0f5;
          border: none;
          border-radius: 35px;
        }
        .password {
          display: flex;
          align-items: center;
          margin: 20px auto;
          width: 300px;
          color: #3f3f3f;
          font-size: 14px;
          padding: 20px 30px;
          background-color: #edf0f5;
          border: none;
          border-radius: 35px;
          & > input {
            flex: 1;
            padding: 0;
            margin: 0;
            outline: 0;
            border: 0;
          }
          .visibility_icon {
            margin: auto;
            font-size: 20px;
          }
        }
      }
      a {
        &.lite {
          padding: 20px 0;
          color: #949494;
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
        text-align: center;
        color: #949494;
        .dark {
          color: #353434 !important;
          text-decoration: none;
          font-weight: 600 !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
