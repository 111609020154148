a,
button {
  &.red_w {
    border: none;
    outline: none;
    justify-self: center;
    font-size: 18px !important;
    padding: 20px 60px;
    border-radius: 50px;
    text-decoration: none;
    max-width: fit-content;
    text-align: center;
    background-color: #c70100;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 20px !important;
      padding: 24px 50px;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
  &.c_width {
    border: none;
    outline: none;
    margin-top: 20px !important;
    width: 300px !important;
    justify-self: center;
    font-size: 14px !important;
    padding: 20px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    background-color: #c70100;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 16px !important;
      padding: 20px 50px;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
}
