// Dashboard Associated components Style rule
@mixin scrollBar() {
  --scrollbarBG: #f6f7f9;
  --thumbBG: #6d6d6d;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 10px;
    padding: 5px;
    border: 3px solid var(--scrollbarBG);
  }
}
.rounded_demo {
  width: 50px;
  height: 50px;
  justify-self: center;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 40px;
  color: #fff;
  background-color: #c70100;
}

// Drown is used to hide components

.drown {
  display: none;
}

.small_avatar {
  border-radius: 50%;
  width: 70px;
  height: auto;
}

.j_center {
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  text-align: center;
}

.s_container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
}

.appointment {
  width: max(200px, 100%);
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  padding: 25px 0;
  margin: 10px auto;
  .month,
  .quantity,
  .day_left {
    font-size: 15px;
    color: #6d6d6d;
  }
  .date {
    display: grid;
    justify-items: center;
  }
}

.badge {
  padding: 10px 10px !important;
  .badge_name {
    font-size: 16px;
    padding-top: 5px !important;
    text-align: center !important;
    font-weight: 700;
    color: #6d6d6d !important;
  }
}

.hospital_dBox {
  box-shadow: 0 0 10px 1px #c0c0c079;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 10px;
  max-width: 300px;
  height: fit-content;
  margin: 10px;
  .top_icoBox {
    margin-left: 5px;
    text-align: right !important;
    display: flex;
    justify-content: space-between !important;
    .details_container {
      display: flex;
      align-items: center;
      gap: 8px;
      .sign {
        display: grid;
        place-items: center;
        color: #c70100;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 10px 5px #c0c0c079;
        margin: 4px;
        height: 45px;
        width: 45px;
      }
      .h_address {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 8px;
        h6 {
          color: #6d6d6d;
          font-size: 13px !important;
          display: flex;
          gap: 8px;
          .icons {
            font-size: 13px !important;
          }
        }
      }
    }
    .icon {
      color: #c70100;
      font-size: 30px !important;
      text-align: right !important;
    }
  }
}

.history_box {
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin: 20px auto;
  border-bottom: 1px solid #6d6d6d;
  .serial {
    background-color: #c70100;
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-weight: 900 !important;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  h4,
  .times {
    color: #6d6d6d;
  }
}

.review_box {
  margin: 10px auto;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px #c0c0c079;
  background-color: #fff;
  max-width: 550px;
  .name_time {
    padding-left: 20px;
    h3 {
      color: #343434;
    }
    h5 {
      color: #6d6d6d;
    }
  }
  .review {
    color: #6d6d6d;
    padding: 10px 0;
  }
  .like_reply {
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
    color: #c70100;
    .icons {
      font-size: 14px;
      margin: 0 10px;
    }
  }
}

.notificationBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 0 10px 1px #c0c0c079;
  background-color: #fff;
  .bloodGroup {
    width: 60px;
    aspect-ratio: 1/1;
    display: grid;
    place-items: center;
    color: #c70100;
    font-weight: 900 !important;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px 5px #c0c0c079;
  }
  .data_group {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    @media (max-width: 548px) {
      gap: 4px;
    }
  }
  .notificationDetails {
    max-width: 150px !important;
    P {
      color: #6d6d6d;
      span {
        font-size: 16px;
        font-weight: 700px !important;
        color: #000;
      }
    }
  }
  .timeAgo {
    font-size: 13px;
    color: #6d6d6d;
    margin: auto 0;
  }
}

// Dashboard style rule

div {
  &.dashboard {
    display: flex;
    nav {
      height: 100vh;
      top: 0;
      width: 250px;
      background-color: #fff;
      padding: 30px 0 20px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &.fly {
        @media (max-width: 1024px) {
          position: fixed;
          width: 25vw;
          display: grid;
          z-index: 10000;
        }
        @media (max-width: 768px) {
          position: fixed;
          width: 40vw;
          display: grid;
          z-index: 10000;
        }
        @media (max-width: 548px) {
          position: fixed;
          width: 80vw;
          display: grid;
          z-index: 10000;
        }
      }
      @media (max-width: 1024px) {
        display: none;
      }
      img {
        justify-self: center;
        width: 50%;
        margin: 0 auto;
        &.poster {
          margin-top: 40px;
          width: 80% !important;
          border-radius: 24px;
        }
      }
      .nav_items {
        display: flex;
        flex-direction: column;
        gap: 12px;
        a {
          margin: 0 30px;
          padding: 10px 20px;
          display: flex;
          height: max-content;
          overflow: hidden;
          align-items: center;
          text-align: center;
          font-size: 14px;
          text-decoration: none;
          color: #343434;
          @media (max-width: 548px) {
            font-size: 16px;
          }
          &.active {
            color: #c70100;
            background-color: #f6f7f9;
            border-right: solid 6px #c70100;
          }
          & > * {
            font-size: 20px;
            margin-right: 10px;
            @media (max-width: 548px) {
              font-size: 25px;
            }
          }
        }
      }
    }
    &-body {
      flex: 1;
      background-color: #f6f7f9;
      height: 100vh;
      overflow-y: auto;
      @include scrollBar();
      header {
        padding: 40px 40px 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        background-color: #f6f7f9;
        @media (max-width: 600px) {
          padding: 20px;
        }
        @media (max-width: 397px) {
          gap: 12px;
        }
        .left_col {
          display: flex;
          flex-direction: row;
          align-items: center;
          div {
            display: flex;
          }
          h3 {
            font-size: 18px;
            padding-right: 20px;
          }
          h6 {
            font-size: 12px;
            padding-right: 20px;
          }
          .toggleIco {
            font-size: 30px;
            margin: 0 5px;
            color: #c70100;
          }
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            align-content: center;
            h3 {
              color: #000;
            }
          }
        }
        .right_col {
          display: flex;
          align-items: center;
          text-align: right;
          justify-self: flex-end;
          margin-left: auto;
          img {
            margin-left: 20px;
            width: 60px;
            height: auto;
          }
          .vertigo {
            color: #c70100;
            font-size: 30px !important;
          }
        }
        .middle_col {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      // Home Style rules
      .home_container {
        padding: 0 20px 0 30px;
        @media (max-width: 1024px) {
          padding: 0 20px;
        }
        .campaigns {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          overflow: hidden;
          position: relative;
          height: calc(((320px * 8) / 16));
          &_slides {
            display: flex;
            gap: 24px;
            flex-direction: row;
            flex-wrap: nowrap;
            width: max-content;
            padding: 0 20px;
            position: absolute;
            top: 10px;
            left: 0;
            .campaign_slides-slide {
              width: 300px;
              aspect-ratio: 16/8;
              position: relative;
              overflow: hidden;
              border-radius: 16px;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .arrowIco {
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #c70100;
            color: #fff;
            width: 30px;
            aspect-ratio: 1/1;
            display: grid;
            place-items: center;
            font-size: 20px;
            border-radius: 50%;
            z-index: 10;
            transform: translateY(-50%);
            &:first-child {
              & > * {
                transform: translateX(15%);
              }
            }
            &:last-child {
              & > * {
                transform: translateX(5%);
              }
            }
            &.l {
              right: 0;
              left: auto;
            }
          }
        }
        &__grid {
          gap: 24px;
          grid-template-columns: 4fr 8fr;
          @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 548px) {
            grid-template-columns: repeat(1, 1fr);
          }
          &-menus {
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            &_menu {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              @media (max-width: 548px) {
                justify-content: center;
              }
              & > a {
                text-decoration: none;
                font-weight: 600 !important;
                color: black;
              }
            }
          }
          &-requests {
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            &_reqS {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              @media (max-width: 548px) {
                justify-content: center;
              }
              & > a {
                text-decoration: none;
                font-weight: 600 !important;
                color: black;
              }
            }
            img {
              height: auto;
              width: 100%;
              padding: 20px 10px 20px 0;
              margin: auto;
            }
          }
          .map_container {
            align-items: center;
            padding: 0 0 30px;
            h3 {
              padding: 20px 0 30px;
              display: block !important;
              text-align: left;
            }
            & > iframe {
              border: none;
              width: 100%;
              aspect-ratio: 8/4;
              border-radius: 16px;
            }
          }
        }
      }
      // Profile Style rules
      .profile_container {
        grid-template-columns: 4fr 8fr;
        padding: 0 20px;
        gap: 24px;
        @media (max-width: 548px) {
          grid-template-columns: 1fr;
        }
        &__grid {
          display: grid;
          gap: 24px;
        }
        section {
          &.profile {
            margin: 20px 0;
            .profile_box {
              box-shadow: 0 0 10px 1px #c0c0c079;
              background-color: #fff;
              border-radius: 24px;
              padding: 16px;
              .cover_container {
                width: 100%;
                aspect-ratio: 16/9;
                overflow: hidden;
                border-radius: 24px;
                position: relative;
                & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
                .title_container {
                  color: #fff;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  padding: 16px;
                  width: 100%;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.705) 0%,
                    rgba(0, 0, 0, 0.247) 100%
                  );
                }
              }
              .details_container {
                padding-left: 16px;
                .info {
                  padding: 10px 0;
                  h4 {
                    padding: 10px 0;
                  }
                  P {
                    color: #6d6d6d;
                  }
                }
                .badgeContainer {
                  padding: 10px 0 !important;
                  &_flex {
                    padding-top: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                  }
                }
                .progress_bar {
                  margin: 24px auto;
                  height: 13px;
                  background-color: #9e9e9e;
                  border-radius: 10px;
                  .progress {
                    height: 100%;
                    width: 80%;
                    border-radius: 10px;
                    background-color: #c70100;
                  }
                }
                p.info_next {
                  padding: 0;
                  color: #6d6d6d;
                  font-weight: 500;
                  span {
                    color: #c70100;
                  }
                }
              }
            }
          }
          &.Status {
            padding: 24px;
            background-color: #fff;
            margin: 20px 0px;
            border-radius: 24px;
            // height: fit-content;
            box-shadow: 0 0 10px 1px #c0c0c079;
            .nav {
              margin: 0 auto;
              height: 30px;
              .nav_item {
                cursor: pointer;
                color: #000;
                padding: 5px;
                margin: auto 10px;
                background-color: transparent;
                border: none;
                outline: none;
                &.active {
                  display: inline;
                  color: #c70100;
                  border-bottom: 2px solid #c70100;
                }
              }
            }
            .status_content {
              align-items: center;
              margin: 10px 0;
              a {
                color: #000;
              }
            }
          }
          &.badges {
            padding: 24px 28px;
            background-color: #fff;
            border-radius: 24px;
            box-shadow: 0 0 10px 1px #c0c0c079;
            .badges_flex {
              padding-top: 16px;
              display: flex;
              gap: 16px;
              flex-wrap: wrap;
            }
          }
        }
      }
      // Donations style rule
      .donation_container {
        padding: 0 20px 0 30px;
        @media (max-width: 1024px) {
          padding: 0 20px;
        }
        .hospitalDescription {
          display: flex;
          flex-wrap: wrap;
          @media (max-width: 768px) {
            align-items: center;
            justify-content: center;
          }
        }
        &__grid {
          gap: 24px;
          .donation_history {
            padding: 20px 0;
            .history_container {
              box-shadow: 0 0 10px 1px #c0c0c079;
              background-color: #fff;
              border-radius: 20px;
              padding: 30px;
              margin-top: 20px;
            }
            .histories {
              padding-top: 20px;
            }
          }
          .donation_review {
            padding: 20px 0;
            h2 {
              padding-bottom: 10px !important;
            }
          }
        }
      }
      // Donor List
      .donor_list {
        padding: 0 20px 0 30px;
        @media (max-width: 1024px) {
          padding: 0 20px;
        }
      }
      .setting_container {
        padding: 0 20px 0 30px;
        @media (max-width: 1024px) {
          padding: 0 20px;
        }
      }
      // Notification style rules
      .notification_container {
        padding: 0 20px;
        gap: 24px;
        .notifications {
          padding: 20px;
          height: 80vh;
          overflow-y: scroll;
          overflow-x: hidden;
          @include scrollBar();
          @media (max-width: 548px) {
            height: max-content;
            padding: 20px 0;
          }
          & > * {
            margin: 16px 0;
          }
          &.leftLine {
            border-left: 2px solid #dfdfdf;
            @media (max-width: 548px) {
              border: none;
            }
          }
        }
      }
    }
  }
}
