// Base directory imported

@import "./base/base";
@import "./base/fonts";
@import "./base/typography";

// component directory imported

@import "./components/buttons";
@import "./components/flex";
@import "./components/grid";

// page directory imported

@import "./pages/notfound";
@import "./pages/welcome";
@import "./pages/signin";
@import "./pages/signup";
@import "./pages/dashboard";

.app-container {
  width: min(1440px, 100%);
  margin: 0 auto;
}
