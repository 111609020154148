main {
  &.pageNotFound {
    width: 100vw;
    height: 100vh;
    display: flex;
    & > div {
      margin: auto;
      padding: 100px;
      font-size: 35px;
      text-align: center;
    }
  }
}
