main {
  &.welcome {
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    img {
      justify-self: center;
      height: 200px;
      width: auto;
      margin-bottom: 100px;
    }
  }
}
