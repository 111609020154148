// base or core style declared

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  overflow-x: hidden;
  transition: all 300ms ease-in-out;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    color: #6d6d6d;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #6d6d6d;
    border-radius: 4px;
    background: transparent;
  }
}

html {
  scroll-behavior: smooth !important;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  background-color: #f1f1f17c;
}
