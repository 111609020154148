.grid_parent {
  display: grid;
}

.grid_twoCol {
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 548px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid_threeCol {
  grid-template-columns: repeat(3, 100px);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 548px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid_fourCol {
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 548px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid_twelveCol {
  grid-template-columns: repeat(12, 1fr);
}

.grid_fiveCol {
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid_fiveRow {
  grid-template-rows: repeat(5, 1fr);
}
.grid_sixRow {
  grid-template-rows: repeat(6, 1fr);
}
