h1 {
  font-size: 18px;
}

h2 {
  font-size: 25px;
  font-weight: 200 !important;
}

h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 12px;
}
p {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}
