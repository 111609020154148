.t_center {
  text-align: center;
}
div {
  &.signUp {
    width: 100vw;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
    @media (max-width: 548px) {
      padding: 20px;
      padding-bottom: 80px;
    }
    section {
      align-items: center;
      justify-content: center;
      display: grid;
      //in this section the left hand side's logo with text beneath style rule are defined
      &.signUp_left {
        align-items: center;
        img {
          justify-self: center;
          align-self: center;
          width: auto;
          height: 180px;
        }
        h1 {
          max-width: 400px;
          text-align: center;
          font-weight: 300 !important;
          font-size: 18px;
          color: #343434;
          @media (max-width: 768px) {
            max-width: 80%;
            margin: auto;
          }
        }
      }
      &.signUp_right {
        display: flex;
        // common styles are defined here
        width: 100%;
        .form {
          display: grid;
          justify-items: center;
          // margin: auto;
          width: min(300px, 100%);
          h1 {
            padding-bottom: 20px;
            width: 100%;
            font-size: 25px;
            font-family: "Roboto", sans-serif;
            text-align: center;
            @media (max-width: 768px) {
              padding-top: 30px;
            }
          }
          label {
            color: #949494;
            font-size: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;
          }
          input {
            margin: 20px auto;
            width: 100%;
            color: #949494;
            font-size: 14px;
            padding: 20px 30px;
            background-color: #edf0f5;
            border: none;
            border-radius: 35px;
          }
          .password {
            display: flex;
            align-items: center;
            margin: 20px auto;
            width: 100%;
            color: #3f3f3f;
            font-size: 14px;
            padding: 20px 30px;
            background-color: #edf0f5;
            border: none;
            border-radius: 35px;
            & > input {
              flex: 1;
              padding: 0;
              margin: 0;
              outline: 0;
              border: 0;
            }
            .visibility_icon {
              margin: auto;
              font-size: 20px;
            }
          }
          // sign up iii style rule defined
          .radios {
            margin: 20px auto;
            justify-items: center;
            grid-gap: 10px;
            div {
              &.select {
                color: #c70100;
                border: solid 1px #c70100;
                width: 60px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                font-size: 16px !important;
                font-weight: 500;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.selected {
                  color: #fff;
                  background-color: #c70100;
                }
              }
            }
          }
          // sign up iv & v style rule defined

          .weights,
          .genders {
            padding: 30px 0;
            display: flex;
            .weight,
            .gender {
              position: relative;
              margin: auto 20px;
              display: grid;
              background-color: #f6f7f9;
              justify-content: center;
              padding: 20px;
              border-radius: 20px;
              box-shadow: 0 0 5px #f6f6f6;
              border: 1px solid #c70100;
              overflow: hidden;
              cursor: pointer;
              .top_arrow {
                background-color: #c70100;
                padding: 5px;
                position: absolute;
                color: #edf0f5;
                font-size: 30px !important;
                border-radius: 50%;
                margin-top: 2px;
                margin-left: 2px;
              }
              img {
                width: min(70px, 100%);
                border-radius: 50%;
              }
              p {
                padding: 30px 0 0 0;
                text-align: center;
                font-size: 16px;
                color: #343434;
                font-weight: 700;
              }
              &.active {
                background-color: #c70100;
                color: #f6f6f6 !important;
                .top_arrow {
                  background-color: #f6f6f6;
                  color: #c70100;
                }
                p {
                  color: #f6f6f6;
                }
              }
            }
          }
          // sign up f style rule defined

          .file_upload {
            display: grid;
            place-items: center;
            height: 180px;
            width: 180px;
            box-shadow: 0 0 5px #f6f6f6;
            background-color: #f6f7f9;
            border-radius: 50%;
            position: relative;
            input {
              position: absolute;
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              outline: none;
              opacity: 0 !important;
              z-index: 5;
              cursor: pointer;
            }
            &__image {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              object-fit: scale-down;
              object-position: center;
            }
            .drag-text {
              padding: 0 20%;
              display: grid;
              place-items: center;
              text-align: center !important;
              .upIco {
                justify-self: center !important;
                font-size: 40px !important;
                color: #c70100;
              }
              h3 {
                font-size: 12px;
                font-weight: 900 !important;
              }
            }
          }
          &.page-7 {
            input {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
